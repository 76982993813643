<!-- 关注并折扣行为 卖点标签位组件 -->
<template>
  <div 
    v-if="userBehaviorLabels.labelLang"
    class="follow-discount-behavior" 
  >
    <div class="follow-discount-behavior_content">
      <!-- AR站 -->
      <template v-if="GB_cssRight">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6486 5.11016C11.1172 5.5788 11.1171 6.33857 10.6485 6.80718L7.22088 10.2348C6.75223 10.7035 5.9924 10.7035 5.52378 10.2348L1.8811 6.59175C1.57492 6.28555 1.41601 5.86197 1.44523 5.42994L1.66493 2.18108C1.69868 1.68196 2.09606 1.28458 2.59518 1.25083L5.84395 1.03113C6.27603 1.00191 6.69966 1.16087 7.00587 1.46711L10.6486 5.11016ZM4.37851 5.11536C3.68816 5.11536 3.12851 4.55572 3.12851 3.86536C3.12851 3.175 3.68816 2.61536 4.37851 2.61536C5.06887 2.61536 5.62851 3.175 5.62851 3.86536C5.62851 4.55572 5.06887 5.11536 4.37851 5.11536Z" fill="white"/>
        </svg>
      </template>
      <template v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.35143 5.11016C0.882841 5.5788 0.882859 6.33857 1.35147 6.80718L4.77912 10.2348C5.24777 10.7035 6.0076 10.7035 6.47622 10.2348L10.1189 6.59175C10.4251 6.28555 10.584 5.86197 10.5548 5.42994L10.3351 2.18108C10.3013 1.68196 9.90394 1.28458 9.40482 1.25083L6.15605 1.03113C5.72397 1.00191 5.30034 1.16087 4.99413 1.46711L1.35143 5.11016ZM7.62149 5.11536C8.31184 5.11536 8.87149 4.55572 8.87149 3.86536C8.87149 3.175 8.31184 2.61536 7.62149 2.61536C6.93113 2.61536 6.37149 3.175 6.37149 3.86536C6.37149 4.55572 6.93113 5.11536 7.62149 5.11536Z" fill="white"/>
        </svg>
      </template>
      <p>{{ userBehaviorLabels.labelLang }}</p>
    </div>
    <!-- <div class="follow-discount-behavior_icon">
      <s-popover
        v-model="showPopover"
        :content="tipContent"
        :show-close-icon="true"
        trigger-type="user"
        placemen="top"
        custom-class="tip-popover"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" class="icon-explain" slot="reference" @click.stop="clickShowPopover">
          <path d="M6 1.49976C8.48528 1.49976 10.5 3.51447 10.5 5.99976C10.5 8.48504 8.48528 10.4998 6 10.4998C3.51472 10.4998 1.5 8.48504 1.5 5.99976C1.5 3.51447 3.51472 1.49976 6 1.49976Z" stroke="#767676"/>
          <rect x="5.5" y="3.5" width="1" height="1" fill="#767676"/>
          <rect x="5.5" y="5.5" width="1" height="3" fill="#767676"/>
        </svg>
      </s-popover>
    </div> -->
  </div>
</template>


<script setup name="followDiscountBehavior">
// import { ref, defineProps, computed, watch, defineEmits } from 'vue'
import { defineProps } from 'vue'

// const emits = defineEmits(['clickShowPopover'])

const props = defineProps({
  userBehaviorLabels: {
    type: Object,
    default: () => {},
    required: true
  },
  // scrollPage: {
  //   type: Boolean,
  //   default: false
  // },
  // index: {
  //   type: [String, Number],
  //   default: ''
  // },
  // nowShowPopoverIndex: {
  //   type: [String, Number],
  //   default: ''
  // },
})

// const tipContent = computed(() => {
//   return props.userBehaviorLabels?.description
// })

// 控制展示popover，用默认的会导致事件冒泡触发商卡的跳转
// const showPopover = ref(false)

// watch(() => props.scrollPage, (val) => {
//   if (val) {
//     showPopover.value = false
//   }
// })
// watch(() => props.nowShowPopoverIndex, (val) => {
//   if (props.index !== val) {
//     showPopover.value = false
//   }
// })
// const clickShowPopover = ()=>{
//   emits('clickShowPopover')
//   showPopover.value = !showPopover.value
// }

const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
</script>
<style lang="less">
.follow-discount-behavior {
  display: flex;
  flex-direction: row;
  &_content{
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2px 2px 1px 2px;
    border-radius: 2px;
    background: #FF9000;
    color: #FFF;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-height: 16px;
    max-width: calc(100% - 14px); /** 后面的icon大小 + 间距 */
    svg {
      margin-right: 2px;
      min-width: 12px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &_icon {
    display: flex;
    align-items: center;
    margin-left: 2px;
    min-width: 12px;
    .sui-popover__container {
      display: inline-flex;
      align-items: center;
    }
  }
}
.tip-popover {
  max-width: 240/37.5rem;
  z-index: 1;
  .sui-popover__content-body {
    color: #FFF;
    font-size: 14 / @w-375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.32rem 0.32rem;
    border-radius: 2px;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.80));
  }
  .sui-popover__close-arrow {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 12 / @w-375rem !important;
  }
}
</style>
