<!-- 降价行为 卖点标签位组件 -->
<template>
  <div 
    v-if="userBehaviorLabels.labelLang"
    class="price-drop-behavior" 
  >
    <div class="price-drop-behavior_content">
      <!-- AR站 -->
      <template v-if="GB_cssRight">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12 13" fill="none">
          <path d="M11.4161 3.19898L8.60803 6.00703L6.37014 3.76914L2.3068 7.8323" stroke="white" stroke-width="1.5"/>
          <path d="M1.97803 3.97412L1.97803 8.375L6.37891 8.375" stroke="white" stroke-width="1.5"/>
        </svg>
      </template>
      <template v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
          <g clip-path="url(#clip0_558_5591)">
            <path d="M1.08391 3.19892L3.89197 6.00697L6.12986 3.76908L10.1932 7.83224" stroke="white" stroke-width="1.5"/>
            <path d="M10.522 3.97406L10.522 8.37494L6.12109 8.37494" stroke="white" stroke-width="1.5"/>
          </g>
          <defs>
            <clipPath id="clip0_558_5591">
              <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
          </defs>
        </svg>
      </template>
      <p>{{ userBehaviorLabels.labelLang }}</p>
    </div>
    <div class="price-drop-behavior_icon">
      <s-popover
        v-model="showPopover"
        :content="tipContent"
        :show-close-icon="true"
        trigger="user"
        placemen="top"
        custom-class="tip-popover"
      >
        <template #reference>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" class="icon-explain" @click.stop="clickShowPopover">
            <path d="M6 1.49976C8.48528 1.49976 10.5 3.51447 10.5 5.99976C10.5 8.48504 8.48528 10.4998 6 10.4998C3.51472 10.4998 1.5 8.48504 1.5 5.99976C1.5 3.51447 3.51472 1.49976 6 1.49976Z" stroke="#767676"/>
            <rect x="5.5" y="3.5" width="1" height="1" fill="#767676"/>
            <rect x="5.5" y="5.5" width="1" height="3" fill="#767676"/>
          </svg>
        </template>
      </s-popover>
    </div>
  </div>
</template>


<script setup name="priceDropBehavior">
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { ref, defineProps, computed, watch, defineEmits } from 'vue'

const emits = defineEmits(['clickShowPopover'])

const props = defineProps({
  userBehaviorLabels: {
    type: Object,
    default: () => {},
    required: true
  },
  scrollPage: {
    type: Boolean,
    default: false
  },
  index: {
    type: [String, Number],
    default: ''
  },
  nowShowPopoverIndex: {
    type: [String, Number],
    default: ''
  },
})

const tipContent = computed(() => {
  return props.userBehaviorLabels?.description
})

// 控制展示popover，用默认的会导致事件冒泡触发商卡的跳转
const showPopover = ref(false)

watch(() => props.scrollPage, (val) => {
  if (val) {
    showPopover.value = false
  }
})
watch(() => props.nowShowPopoverIndex, (val) => {
  if (props.index !== val) {
    showPopover.value = false
  }
})
const clickShowPopover = ()=>{
  emits('clickShowPopover')
  showPopover.value = !showPopover.value
}

const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
</script>
<style lang="less">
.price-drop-behavior {
  display: flex;
  flex-direction: row;
  &_content{
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2px 2px 1px 2px;
    border-radius: 2px;
    background: #F73E44;
    color: #FFF;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-height: 16px;
    max-width: calc(100% - 14px); /** 后面的icon大小 + 间距 */
    svg {
      margin-right: 2px;
      min-width: 12px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &_icon {
    display: flex;
    align-items: center;
    margin-left: 2px;
    min-width: 12px;
    .sui-popover__container {
      display: inline-flex;
      align-items: center;
    }
  }
}
.tip-popover {
  max-width: 240/37.5rem;
  z-index: 1;
  .sui-popover__content-body {
    color: #FFF;
    font-size: 14 / @w-375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.32rem 0.32rem;
    border-radius: 2px;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.80));
  }
  .sui-popover__close-arrow {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 12 / @w-375rem !important;
    right: 1px;
    top: 1px;
  }
}
</style>
